






























import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { ProductSpecification } from '../../../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ColumnSection>({
  name: 'ColumnSection'
})
export class ColumnSection extends Vue {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ required: true, type: Object })
  public column!: ProductSpecification['columnSection']
}

export default ColumnSection
